.legend-card {
  width: 250px;
  position: absolute;
  top: 10px;
  left: 18px;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  max-width: 90vw;
}

.color-box {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border: 1px solid #333;
  border-radius: 2px;
  display: inline-block;
}

.legend-card p {
  margin: 0;
  word-wrap: break-word;
  white-space: normal;
}

.text-content {
  font-weight: 500;
}
